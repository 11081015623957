.home-welcome {
  padding: 100px 200px;
  position: relative;
  width: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: rgb(241, 241, 241);
  z-index: 0;
}
.home-welcome::before {
  /* z-index: 777; */
  content: "";
  position: relative;
  background: rgba(3, 96, 251, 0.3);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.home-welcome .content-welcome {
  z-index: 888;
  color: #fff;
  width: 60%;
  margin-top: 50px;
  /* display: none; */
}
.home-welcome .content-welcome .div-welcome {
  /* font-size: 4em; */
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 25px;
  margin-top: -15px;
}
.home-welcome .content-welcome .div-welcome span {
  font-size: 1.3em;
  font-family: 'Aboreto', cursive;
}
.home-welcome .content-welcome p {
  margin-bottom: 65px;
  font-size: 1em;
  width: 90%;
  letter-spacing: 0.5px;
  font-family: 'Nanum Pen Script', cursive;
  text-transform: capitalize;
}
.home-welcome .media-icons-welcome {
  z-index: 888;
  position: absolute;
  right: 30px;
  display: flex;
  flex-direction: column;
  transition: 0.5s ease;
}
.home-welcome .media-icons-welcome a {
  color: #fff;
  font-size: 1.6em;
  transition: 0.3s ease;
}
.home-welcome .media-icons-welcome a:not(:last-child) {
  margin-bottom: 20px;
}
.home-welcome .media-icons-welcome a:hover {
  transform: scale(1.3);
}
.home-welcome-2 img {
  border-radius: 00px 0px -10px 0px;
}
.home-welcome img {
  z-index: 000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 2s ease;
  border-radius: 00px 0px 100px 0px;
}
.slider-navigation-welcome {
  z-index: 888;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(80px);
  margin-bottom: 12px;
  transition: all 0.45s;
}
.slider-navigation-welcome .nav-btn-welcome {
  width: 12px;
  height: 12px;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 2px rgba(255, 255, 255, 0.5);
  transition: 0.3s ease;
}
.slider-navigation-welcome .nav-btn-welcome:not(:last-child) {
  margin-right: 20px;
}
.slider-navigation-welcome .nav-btn-welcome:hover {
  transform: scale(1.7);
}
.slider-navigation-welcome .nav-btn-welcome:active {
  background: #2696e9;
}

/* Btn */
.btn-container-welcome {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.btn-container-welcome a {
  position: relative;
  display: inline-block;
  padding: 15px 30px;
  border: 2px solid #0f0;
  margin: 10px 40px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: small;
  text-decoration: none;
  letter-spacing: 2px;
  color: #fff;
  -webkit-box-reflect: below 0px linear-gradient(transparent, #0002);
  transition: 0.5s;
  transition-delay: 0s;
}
.btn-container-welcome a:hover {
  transition-delay: 1.5s;
  color: #000;
  box-shadow: 0 0 10px #0f0, 0 0 20px #0f0, 0 0 40px #0f0, 0 0 80px #0f0,
    0 0 160px #0f0;
}
.btn-container-welcome a span {
  position: relative;
  z-index: 100;
}
.btn-container-welcome a:nth-child(2) {
  filter: hue-rotate(80deg);
}
.btn-container-welcome a::before {
  content: " ";
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 2px;
  background: #0f0;
  box-shadow: 5px -8px 0 #0f0, 5px 8px 0 #0f0;
  transition: width 0.5s, left 0.5s, height 0.5s, box-shadow 0.5s;
  transition-delay: 1s, 0.5s, 0s, 0s;
}
.btn-container-welcome a:hover::before {
  width: 60%;
  height: 100%;
  left: -2px;
  box-shadow: 5px 0px 0 #0f0, 5px 0px 0 #0f0;
  transition-delay: 0s, 0s, 1s, 1s;
}
.btn-container-welcome a::after {
  content: " ";
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 2px;
  background: #0f0;
  box-shadow: -5px -8px 0 #0f0, -5px 8px 0 #0f0;
  transition: width 0.5s, left 0.5s, height 0.5s, box-shadow 0.5s;
  transition-delay: 1s, 0.5s, 0s, 0s;
}
.btn-container-welcome a:hover::after {
  width: 60%;
  height: 100%;
  right: -2px;
  box-shadow: -5px 0px 0 #0f0, -5px 0px 0 #0f0;
  transition-delay: 0s, 0s, 1s, 1s;
}
/* h1  */
.home-welcome .content-welcome .h1-welcome {
  position: relative;
  font-weight: 600;
  font-size: 3.5em;
  color: #252839;
  -webkit-text-stroke: 0.3vw #383d52;
  text-transform: uppercase;
}
.home-welcome .content-welcome .h1-welcome::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  color: #0f0;
  -webkit-text-stroke: 0vw #383d52;
  border-right: 2px solid #0f0;
  overflow: hidden;
  animation: animate1 6s linear infinite;
}
.projects-welcome {
  position: relative;
}
@keyframes animate1 {
  0%,
  10%,
  100% {
    width: 0%;
  }
  70%,
  90% {
    width: 100%;
  }
}
@media (max-width: 1040px) {
  .home-welcome {
    padding: 100px 45px;
  }
  .home-welcome .media-icons-welcome {
    right: 15px;
  }
  .home-welcome .content-welcome {
    width: 70%;
  }
  .home-welcome .content-welcome .h1-welcome {
    font-size: 2.5em;
  }
  .home-welcome .content-welcome .div-welcome span {
    font-size: 1em;
  }
  .home-welcome .content-welcome p {

    font-size: 0.8em;

  }
}
@media (max-width: 600px) {
  .home-welcome {
    padding: 100px 45px;
  }
  .home-welcome .media-icons-welcome {
    right: 15px;
  }
  .home-welcome .content-welcome {
    width: 70%;
  }
  .home-welcome .content-welcome .h1-welcome {
    font-size: 1.2em;
  }
  .home-welcome .content-welcome .div-welcome span {
    font-size: 0.7em;
  }
  .home-welcome .content-welcome p {

    font-size: 0.5em;

  }
  .btn-container-welcome a {
    font-weight: 400;
    font-size: 0.2em;
    padding: 10px 10px;
  }
}
@media (max-width: 370px){
  .home-welcome .content-welcome .h1-welcome {
    font-size: 0.9em;
  }
}
/* projects-welcome */
.projects-body-welcome {
  display: flex;
  justify-content: center;
  align-self: center;
  min-height: 100vh;
  background: rgb(241, 241, 241);;
}
.projects-container-welcome {
  position: relative;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-template-rows: minmax(100px, auto);
  margin: 40px;
  grid-auto-flow: dense;
  grid-gap: 10px;
}
.projects-container-welcome .projects-box-welcome {
  background: rgb(255, 255, 255);
  padding: 20px;
  display: grid;
  font-size: 20px;
  place-items: center;
  text-align: center;
  color: #333;
  transition: 0.5s;
  border: 2px solid black;
  border-radius: 10px;
  transition: 0.5s;
}
.projects-container-welcome .projects-box-welcome:hover {
  border-color: #ff005d;
  transition: 0.5s;
}
.projects-container-welcome .projects-box-welcome:hover p {
  font-size: large;
  transition: 0.9s;
  background: -webkit-linear-gradient( #03a9f4, #ff0058);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.projects-container-welcome .projects-box-welcome:hover img{
  transition: 2s;
  width: 90px;
}
.projects-container-welcome .projects-box-welcome p {
  font-size: 13px;
}
.projects-container-welcome .projects-box-welcome img {
  position: relative;
  max-width: 130px;
  margin-bottom: 10px;
}
.projects-container-welcome .projects-box-welcome:nth-child(1) {
  grid-row: span 1;
  grid-column: span 2;
}
.projects-container-welcome .projects-box-welcome:nth-child(2) {
  grid-row: span 2;
  grid-column: span 1;
}
.projects-container-welcome .projects-box-welcome:nth-child(4) {
  grid-row: span 2;
  grid-column: span 1;
}
.projects-container-welcome .projects-box-welcome:nth-child(5) {
  grid-row: span 1;
  grid-column: span 3;
}
@media (max-width: 900px) {
  .projects-container-welcome .projects-box-welcome:nth-child(1) {
    grid-row: span 1;
    grid-column: span 1;
  }
  .projects-container-welcome .projects-box-welcome:nth-child(2) {
    grid-row: span 1;
    grid-column: span 2;
  }
  .projects-container-welcome .projects-box-welcome:nth-child(4) {
    grid-row: span 1;
    grid-column: span 2;
  }
  .projects-container-welcome .projects-box-welcome:nth-child(3) {
    grid-row: span 2;
    grid-column: span 1;
  }
}
@media (max-width: 750px) {
  .projects-container-welcome {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
    grid-template-rows: minmax(auto, auto);
  }
  .projects-container-welcome .projects-box-welcome {
    grid-row: unset !important;
    grid-column: unset !important;
    margin: 1px 10px;
  }
}
/* about */
.about-container-welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 40px 0;
  background: rgb(241, 241, 241);
}
.about-container-welcome .about-box-welcome {
  position: relative;
  width: 320px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 30px;
  transition: 0.5s;
}
.about-container-welcome .about-box-welcome::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 50px;
  width: 50%;
  height: 100%;
  background: rgb(69, 52, 52);
  border-radius: 8px;
  transform: skewX(15deg);
  transition: 0.5s;
}
.about-container-welcome .about-box-welcome::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 50px;
  width: 50%;
  height: 100%;
  background: rgb(69, 52, 52);
  border-radius: 8px;
  transform: skewX(15deg);
  transition: 0.5s;
  filter: blur(30px);
  transition: 0.5s;
}
.about-container-welcome .about-box-welcome:hover::after,
.about-container-welcome .about-box-welcome:hover::before {
  transform: skewX(0deg);
  left: 20px;
  width: calc(100% - 90px);
}
.about-container-welcome .about-box-welcome:nth-child(1)::after,
.about-container-welcome .about-box-welcome:nth-child(1)::before {
  background: linear-gradient(315deg, #ffbc00, #ff0058);
}
.about-container-welcome .about-box-welcome:nth-child(2)::after,
.about-container-welcome .about-box-welcome:nth-child(2)::before {
  background: linear-gradient(315deg, #03a9f4, #ff0058);
}
.about-container-welcome .about-box-welcome:nth-child(3)::after,
.about-container-welcome .about-box-welcome:nth-child(3)::before {
  background: linear-gradient(315deg, #ffbc00, #ff0058);
}

.about-container-welcome .about-box-welcome span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  pointer-events: none;
}
.about-container-welcome .about-box-welcome span::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: rgba(85, 116, 190, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  transition: 0.5s;
  animation: animate 2s ease-in-out infinite;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}
.about-container-welcome .about-box-welcome:hover span::before {
  top: -50px;
  left: 50px;
  width: 100px;
  height: 100px;
  opacity: 1;
}
.about-container-welcome .about-box-welcome span::after {
  content: " ";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: rgba(85, 116, 190, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  transition: 0.5s;
  animation: animate 2s ease-in-out infinite;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  animation-delay: -1s;
}
.about-container-welcome .about-box-welcome:hover span::after {
  bottom: -50px;
  right: 50px;
  width: 100px;
  height: 100px;
  opacity: 1;
}
@keyframes animate {
  0%,
  100% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(-10px);
  }
}
#stock-stock-welcome{
align-items: center;
justify-content: center;
text-align: center;
padding: 10px;
font-size: 2.5em;
color: #333;
background-color: rgb(241, 241, 241);
}
.about-container-welcome .about-box-welcome .about-content-welcome {
  position: relative;
  left: 0;
  padding: 20px 40px;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  backdrop-filter: blur(10px);
  z-index: 1;
  transition: 0.5s;
  color: #fff;
}
.about-container-welcome .about-box-welcome:hover .about-content-welcome {
  left: -25px;
  padding: 60px 30px;
}
.about-container-welcome .about-box-welcome .about-content-welcome h2 {
  font-size: 1.7em;
  color: #fff;
  margin-bottom: 10px;
}
.about-container-welcome .about-box-welcome .about-content-welcome p {
  font-size: 0.7em;
  margin-bottom: 5px;
  line-height: 1.4em;
  text-transform: capitalize;
}
.about-container-welcome .about-box-welcome .about-content-welcome p h6 {
  font-size: 0.001em;
  color: rgb(114, 114, 114);
}
.about-container-welcome .about-box-welcome .about-content-welcome a {
  display: inline-block;
  font-size: 1.1em;
  color: #111;
  background: #fff;
  padding: 10px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 700;
  transition: 0.9s;
}
.about-container-welcome .about-box-welcome .about-content-welcome a:hover {
  background: #111;
  color: #fff;
  transition: 0.9s;
}
.about-container-welcome .about-box-welcome:nth-child(2) .about-content-welcome a {
  display: inline-block;
  font-size: 1.1em;
  color: #111;
  background: #fff;
  padding: 10px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 700;
  cursor: not-allowed;
  pointer-events: none;
}
.about-container-welcome .about-box-welcome:nth-child(3) .about-content-welcome a {
  display: inline-block;
  font-size: 1.1em;
  color: #111;
  background: #fff;
  padding: 10px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 700;
  cursor: not-allowed;
  pointer-events: none;
}
/* nft */
:root{
  --oxford-blue: rgb(245 242 242);
  --light-oxford-blue: rgb(0, 0, 0);
  --indigo-dye: rgb(0, 0, 0);
  --blue-yonder: rgb(24, 22, 22);
  --aqua: hsl(178,100%, 50%);
  --white: rgb(255, 250, 230);
}
.nft-horizontal-slider {
  display: block;
  width: 100%;
  overflow-x: scroll;
  padding: 50px;
  box-sizing: border-box;
  background: var(--oxford-blue);
  text-transform: capitalize;
}
.nft-horizontal-slider::-webkit-scrollbar {
  display: none;
}
.nft-horizontal-slider {
  -ms-overflow-style: none;
}
.nft-slider-container {
  display: block;
  white-space: nowrap;
}
.nft-item:nth-child(even){
  background: linear-gradient(315deg, #ffbc00, #ff0058);
}
.nft-item:nth-child(odd){
  background: linear-gradient(315deg, #03a9f4, #ff0058);
}


.nft-item{
  margin-right: 50px;
  display: inline-block;
  padding: 20px;
  border-radius: 10px;
  /* box-shadow: 0px 20px 25px 15px rgba(0, 0, 0, 0.05), 0px 40px 30px 15px rgba(0, 0, 0, 0.1) ; */
}
.nft_card_body .nft_wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nft_card_countdown, .nft_card_price{
  display: flex;
  align-items: center;
  font-weight: 500;
}
.nft_card_price{
  color: var(--aqua);
}
.nft_card_icon{
  margin-right: 6px;
}
.nft_card_countdown{
  color: var(--blue-yonder);
}
.nft_card_footer{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.nft_card_author_img{
  width: 34px;
  height: 34px;
}
.nft_card_text{
  color: var(--blue-yonder);
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 23px;
}
.nft_card_product_img{
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}
.nft_card_product_img::after{}
.nft_card_product_img::after{
  --aqua: hsl(178,100%, 50%, 0.5);

  content: url(/Users/owner/Desktop/Authentication/frontend/public/icon-view.svg);
  background: var(--aqua);
  position: absolute;
  inset: 0;
  opacity: 0;
  transition: all 0.25s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 240px;
}
.nft_card_body{
  padding: 23px 0;
  border-bottom: 1px solid var(--indigo-dye);
  margin-bottom: 15px;
}
a{
  text-decoration: none;
}
.nft_card_title{
  color: var(--white);
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 15px;
}
.nft_card_title:hover{
  color: var(--blue-yonder);
}
.nft_card_product_img:hover::after{ opacity: 1;}
.nft_card_product_img img {
  width: 240px;
  height: 240px;
  object-fit: cover;
  display: block;
}
.card_author_img{
  width: 34px;
  height: 34px;
  border: 2px solid var(--white);
  border-radius: 50px;
  margin-right: 15px;
}
.nft_card_author_name{
  color: var(--blue-yonder);
  font-weight: 500;
}
.nft_card_author_name a{
  color: var(--white);
  font-weight: 400;
}
.nft_card_author_name a:hover{
  color: var(--aqua);
  font-weight: 500;
}
/* contact us */
.container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
  background-color:rgb(241, 241, 241);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  width: 100%;
  max-width: 820px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.contact-form {
  background: linear-gradient( #03a9f4, #ff0058);
    position: relative;
}


.circle {
  border-radius: 50%;
  background: linear-gradient(315deg, #03a9f4, #ff0058);
  position: absolute;
}

.circle.one {
  width: 130px;
  height: 130px;
  top: 130px;
  right: -40px;
}

.circle.two {
  width: 80px;
  height: 80px;
  top: 10px;
  right: 30px;
}

.contact-form:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  background: linear-gradient(315deg, transparent 10%, #ffbc00, #ff0058);
  transform: rotate(45deg);
  top: 50px;
  left: -13px;
}

form {
  padding: 2.3rem 2.2rem;
  z-index: 10;
  overflow: hidden;
  position: relative;
}

.title {
  background: -webkit-linear-gradient( #ffbc00, #ff0058);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.7rem;
}

.input-container {
  position: relative;
  margin: 1rem 0;
}

.input {
  width: 100%;
  outline: none;
  border: 2px solid #fafafa;
  background: none;
  padding: 0.6rem 1.2rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 25px;
  transition: 0.3s;
}

textarea.input {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  border-radius: 22px;
  resize: none;
  overflow-y: auto;
}

.input-container label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  padding: 0 0.4rem;
  color: #fafafa;
  font-size: 0.9rem;
  font-weight: 400;
  pointer-events: none;
  z-index: 1000;
  transition: 0.5s;
}

.input-container.textarea label {
  top: 1rem;
  transform: translateY(0);
}

.btn {
  padding: 0.6rem 1.3rem;
  background-color: #fff;
  border: 2px solid #fafafa;
  font-size: 0.95rem;
  background: -webkit-linear-gradient( #ffbc00, #ff0058);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1;
  border-radius: 25px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
}

.btn:hover {
  background-color: transparent;
  color: #fff;
}

.input-container span {
  position: absolute;
  top: 0;
  left: 25px;
  transform: translateY(-50%);
  font-size: 0.8rem;
  padding: 0 0.4rem;
  color: transparent;
  pointer-events: none;
  z-index: 500;
}
.input-container input::placeholder{
color: white;
}
.input-container textarea::placeholder{
  color: white;
  }

.input-container span:before {
  left: 50%;
}



.input-container.focus label {
  top: 0;
  transform: translateY(-50%);
  left: 25px;
  font-size: 0.8rem;
}

.input-container.focus span:before,
.input-container.focus span:after {
  width: 50%;
  opacity: 1;
}

.contact-info {
  padding: 2.3rem 2.2rem;
  position: relative;
}

.contact-info .title {
  font-size: 25px;
  background: -webkit-linear-gradient( #ffbc00, #ff0058);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ;
}

.text {
  color: #333;
  margin: 1.5rem 0 2rem 0;
}

.information {
  display: flex;
  color: #555;
  margin: 0.7rem 0;
  align-items: center;
  font-size: 0.95rem;
}

.icon {
  width: 28px;
  margin-right: 0.7rem;
}

.social-media {
  padding: 2rem 0 0 0;
}

.social-media p {
  color: #333;
}

.social-icons {
  display: flex;
  margin-top: 0.5rem;
}

.social-icons a:nth-child(1) {
  background: linear-gradient(315deg, #ffbc00, #ff0058);

}
.social-icons a:nth-child(2) {
  background: linear-gradient(315deg, #03a9f4, #ff0058);
  
}
.social-icons a:nth-child(3) {
  background: linear-gradient(315deg, #ffbc00, #ff0058);
  
}
.social-icons a:nth-child(4) {
  background: linear-gradient(315deg, #03a9f4, #ff0058);

}
.social-icons a {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  line-height: 35px;
  margin-right: 0.5rem;
  transition: 0.3s;
}

.social-icons a:hover {
  transform: scale(1.05);
}

.contact-info:before {
  content: "";
  position: absolute;
  width: 110px;
  height: 100px;
  border: 22px solid #000;
  border-radius: 50%;
  bottom: -77px;
  right: 50px;
  opacity: 0.3;
}

.big-circle {
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: linear-gradient(315deg, #ffbc00, #ff0058);
  bottom: 50%;
  right: 50%;
  transform: translate(-40%, 38%);
}

.big-circle:after {
  content: "";
  position: absolute;
  width: 360px;
  height: 360px;
  background-color: rgb(244,242,242);
  border-radius: 50%;
  top: calc(50% - 180px);
  left: calc(50% - 180px);
}

.square {
  position: absolute;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(181%, 11%);
  opacity: 0.2;
}
.done-form-contact-us{
  align-items: stretch;
  text-align: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  padding: 10px;
  text-transform: capitalize;
}

@media (max-width: 850px) {
  .form {
    grid-template-columns: 1fr;
  }

  .contact-info:before {
    bottom: initial;
    top: -75px;
    right: 65px;
    transform: scale(0.95);
  }

  .contact-form:before {
    top: -13px;
    left: initial;
    right: 70px;
  }

  .square {
    transform: translate(140%, 43%);
    height: 350px;
  }

  .big-circle {
    bottom: 75%;
    transform: scale(0.9) translate(-40%, 30%);
    right: 50%;
  }

  .text {
    margin: 1rem 0 1.5rem 0;
  }

  .social-media {
    padding: 1.5rem 0 0 0;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 1.5rem;
  }

  .contact-info:before {
    display: none;
  }

  .square,
  .big-circle {
    display: none;
  }

  form,
  .contact-info {
    padding: 1.7rem 1.6rem;
  }

  .text,
  .information,
  .social-media p {
    font-size: 0.8rem;
  }

  .title {
    font-size: 1.15rem;
  }

  .social-icons a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .icon {
    width: 23px;
  }

  .input {
    padding: 0.45rem 1.2rem;
  }

  .btn {
    padding: 0.45rem 1.2rem;
  }
}
/* footer */
.footer {
  display: flex;
  min-height: 60vh;
  justify-content: flex-end;
  align-items: flex-end;
  background:rgb(245 242 242);
}
footer {
  position: relative;
  width: 100%;
  background: linear-gradient( #36bcff  , #ff0058);  min-height: 100px;
  padding: 20px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
footer .social_icon_header,
footer .menu_footer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  flex-wrap: wrap;
}
footer .social_icon_header li,
footer .menu_footer li {
  list-style: none;
}
footer .social_icon_header li a {
  font-size: 2em;
  color: #fff;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
  cursor: pointer;
}
footer .social_icon_header li a:hover {
  transform: translateY(-10px);
}
footer .menu_footer li a {
  font-size: 1.2em;
  color: #fff;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
  text-decoration: none;
  opacity: 0.75;
  cursor: pointer;
}
footer .menu_footer li a:hover {
  opacity: 1;
}
footer p {
  color: #fff;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 1.1em;
}
footer .wave_footer {
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url("../wave.png");
  background-size: 1000px 100px;
}
footer .waves_footer #wave1 {
  z-index: 1000;
  opacity: 1;
  bottom: 0;
  animation: animateWave 4s linear infinite;
}
footer .waves_footer #wave2 {
  z-index: 999;
  opacity: 0.5;
  bottom: 10px;
  animation: animateWave_02 4s linear infinite;
}
footer .waves_footer #wave3 {
  z-index: 1000;
  opacity: 0.2;
  bottom: 15px;
  animation: animateWave 3s linear infinite;
}
footer .waves_footer #wave4 {
  z-index: 999;
  opacity: 0.7;
  bottom: 20px;
  animation: animateWave_02 3s linear infinite;
}
@keyframes animateWave {
  0% {
    background-position-x: 1000px;
  }
  100% {
    background-position-x: 0px;
  }
}
@keyframes animateWave_02 {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: 1000px;
  }
}
/* faq */
.questions{
  padding-top: 65px;
  background: linear-gradient(315deg, #03a9f4, #ff0058);
  border-top:20px ;
}
.body-faq {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(244,242,242)
}
.container-faq {
  margin: 0 40px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.container-faq h1 {
  color: #333;
}
.container-faq .tab-faq {
  position: relative;
  background: #fff;
  padding: 0 20px 20px;
  box-shadow: 0 15px 25px rgba (0, 0, 0, 0.05);
  border-radius: 5px;
  overflow: hidden;
}
.container-faq .tab-faq input {
  appearance: none;
}
.container-faq .tab-faq label {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.container-faq .tab-faq label::after {
  content: "+";
  position: absolute;
  right: 20px;
  font-size: 2em;
  color: rgba(0, 0, 0, 0.1);
  transition: transform 1s;
}
.container-faq .tab-faq:hover label::after {
  color: #333;
}
.container-faq .tab-faq input:checked ~ label::after {
  transform: rotate(135deg);
  color: #fff;
}
.container-faq .tab-faq label h2 {
  width: 40px;
  height: 40px;
  background: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.25em;
  border-radius: 5px;
  margin-right: 10px;
}
.container-faq .tab-faq input:checked ~ label h3{
background: transparent;
padding: 2px 10px;
color: #333;
border-radius: 14px;
box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
.container-faq .tab-faq input:checked ~ label h2{
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
color: rgba(255, 255, 255, 0.1);
font-size: 8em;
justify-content: flex-end;
padding: 20px;
}
.container-faq .tab-faq:nth-child(odd) label h2 {
  background: linear-gradient(135deg, #ffbc00, #ff0058);
}
.container-faq .tab-faq:nth-child(even) label h2 {
  background: linear-gradient(135deg, #03a9f4, #ff0058);
}
.container-faq .tab-faq label h3 {
  position: relative;
  font-weight: 500;
  color: #333;
  z-index: 10;
}
.container-faq .tab-faq .content-faq{
  max-height: 0;
  transition: 1s;
  overflow: hidden;
}
.container-faq .tab-faq input:checked ~ .content-faq{
  max-height: 100vh;
  
}
.container-faq .tab-faq input:checked ~ .content-faq p{
  color: #fff;
}
.container-faq .tab-faq .content-faq p {
  position: relative;
  padding: 10px 0;
  color: #333;
  z-index: 10;
}