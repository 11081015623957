.container-login {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background: #222;
}
.input-box-login{
  position: relative;
  width: 250px;
}
.input-box-login input {
  width: 100%;
  background: transparent;
  color: #fff;
  border: none;
  outline: none;
  box-shadow: none;
  font-size: 1em;
  letter-spacing: 0.1em;
  padding: 10px 0 5px;
}
.input-box-login span {
  position: absolute;
  left: 0;
  padding: 10px 0 5px;
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  pointer-events: none;
  letter-spacing: 0.1em;
  transition: 0.5s;
}
.input-box-login input:valid ~ span,
.input-box-login input:focus ~ span {
  color: #2196f3;
  transform: translateY(-16px);
  font-size: 0.65em;
}
.input-box-login i{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #fff;
  overflow: hidden;
}
.input-box-login i::before{
  content: '';
  position: absolute;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg,#ff1b69, #ff0, #2196f3, #9c27b0,#ff1b69);
  animation: animate 2s linear infinite;
}
.input-box-login input:valid ~ i::before,
.input-box-login input:focus ~ i::before{
  left: 0;
}
@keyframes animate
{
  0%{
    background-position-x: 0;
  }
  100%{
    background-position-x: 250px;
  }
}
