.navbar-header {
  position:absolute;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  z-index: 1;
}
.navbar-header > .nav-logo-header {
  font-weight: 700;
  font-size: 21px;
  margin: 15px;
  color: #ffffff;
}
.navbar-header > .nav-items-header {
  display: flex;
  flex-direction: row;
}

.navbar-header > .nav-items-header li > .a-header {
  color: #ffffff;
  font-size: 16px;
  text-decoration: none;
  margin: 15px;
  position: relative;
  opacity: 0.7;
}
.navbar-header > .nav-items-header li > .a-header:hover {
  opacity: 1;
}
.navbar-header > .nav-items-header li > .a-header::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background: #ffffff;
  transition: all 0.45s;
}
.navbar-header > .nav-items-header li > .a-header:hover:before {
  width: 100%;
}
.navbar-header > .nav-toggle-header {
  display: none;
}

@media (max-width: 700px) {
  .navbar-header > .nav-items-header {
    position: absolute;
    top: 60px;
    display: flex;
    flex-direction: column;
    background-color: #534;
    left: 0;
    background: transparent;
    transform: translateX(-200%);
    transition: all 0.90s;
    border-radius: 0px 0px 70px 0px;
  }
  .navbar-header > .nav-items-header li > .a-header::before {
    background: transparent;
  }
  .navbar-header > .nav-items-header.open {
    transform: translateX(0);
  }
  .navbar-header > .nav-toggle-header {
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .nav-toggle-header > .bar-header {
    position: relative;
    width: 32px;
    height: 2px;
    background: #ffffff;
    transition: all 0.45s ease-in-out;
  }
  .nav-toggle-header > .bar-header::before,
  .nav-toggle-header > .bar-header::after {
    content: "";
    position: absolute;
    height: 2px;
    background: #ffffff;
    border-radius: 2px;
    transition: all 0.45s ease-in-out;
  }
  .nav-toggle-header > .bar-header::before {
    width: 25px;
    transform: translateY(-8px);
    right: 0;
  }
  .nav-toggle-header > .bar-header::after {
    width: 32px;
    transform: translateY(8px);
  }
  .nav-toggle-header.open > .bar-header {
    transform: translateX(-40px);
    background: transparent;
  }
  .nav-toggle-header.open > .bar-header::before {
    width: 32px;
    transform: rotate(45deg) translate(26px, -26px);
  }
  .nav-toggle-header.open > .bar-header::after {
    transform: rotate(-45deg) translate(26px, 26px);
  }
}

.ul-header {
    position: relative;
    display: flex;
    gap: 25px;
    margin: 1%;
}
.ul-header li {
    position: relative;
    list-style: none;
    width: 40px;
    height: 40px;
    background: transparent;
    box-shadow: 0 16px 25px rgba(0, 0, 0, 0.5);
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.5s;
}
.ul-header li:hover {
    width: 180px;
    box-shadow: 0 16px 25px rgba(0, 0, 0, 0);
}
.ul-header li::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 60px;
    background: linear-gradient(45deg, var(--i), var(--j));
    opacity: 0;
    transition: 0.5s;
}
.ul-header li:hover::before {
    opacity: 1;
}
.ul-header li::after {
    content: "";
    position: absolute;
    top: 10px;
    width: 100%;
    height: 100%;
    border-radius: 60px;
    background: linear-gradient(45deg, var(--i), var(--j));
    transition: 0.5;
    filter: blur(15px);
    z-index: -1;
    opacity: 0;
}
.ul-header li:hover::after {
    opacity: 0.5;
}
.ul-header li ion-icon {
    color: #ffffff;
    font-size: 1.6em;
    transition: 0.5s;
    transition-delay: 0.25s;
}
.ul-header li:hover ion-icon {
    transform: scale(0);
    transition-delay: 0s;
}
.ul-header li span {
    position: absolute;
}
.ul-header li .title-header {
    color: #ffff;
    font-size: 1em;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    transform: scale(0);
    transition: 0.5s;
    transition-delay: 0s;
}
.ul-header li:hover .title-header {
    transform: scale(1);
    transition-delay: 0.25s;
}
.a-header{
  text-decoration: none;
  color: #ffff;
}
